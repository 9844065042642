import { getCookie, setCookie } from '/lib/cookie'

const yzModalCloseBtn = document.querySelector('.yz-modal-closebtn')
const yzModalBtnClick = document.querySelector('.yz-modal-cta-btn')
const yzModalCloser = document.querySelector('.yz-modal-terminator')
const yzModalSubmitButton = document.querySelector('.yz-email-form')

if (
  window.location.host === 'yardzendev.localhost' ||
  window.location.host === 'yardzendev.wpengine.com' ||
  window.location.host === 'staging.yardzen.com'
) {
  document
    .querySelector('.yz-modal-cta-email > form')
    .setAttribute(
      'action',
      'https://dashboard.dogfood.yardzen.com/design-profile'
    )
}

const newVisitorPopUpStart = async () => {
  setTimeout(() => {
    document.querySelector('.yz-modal').style.visibility = 'visible'
  }, 5000)
}

function showModal() {
  if (
    window.location.pathname === '/privacy-policy' ||
    window.location.pathname === '/terms-and-conditions' ||
    window.location.pathname === '/contractors'
  ) {
    return false
  }
  return true
}

function isFirstTimeVisitor() {
  const firstTimeVisitDate = getCookie('yzftv')
    ? new Date(getCookie('yzftv'))
    : new Date()
  const today = new Date()
  return (
    firstTimeVisitDate &&
    firstTimeVisitDate.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
  )
}

if (!getCookie('yzmc') && isFirstTimeVisitor() && showModal()) {
  newVisitorPopUpStart()

  yzModalCloseBtn.addEventListener('click', (e) => {
    e.preventDefault()
    document.querySelector('.yz-modal').remove()
    setCookie('yzmc', 'true', 365)
  })
  yzModalSubmitButton.addEventListener('submit', (e) => {
    setCookie('yzmc', 'true', 365)
  })
  yzModalBtnClick.addEventListener('click', (e) => {
    setCookie('yzmc', 'true', 365)
  })
  yzModalCloser.addEventListener('click', (e) => {
    e.preventDefault()
    document.querySelector('.yz-modal').remove()
    setCookie('yzmc', 'true', 365)
  })
}
